<template>
    <div>
        <!-- <div class="main" v-html="artical.content.replace(/<\/?.+?\/?>/g, '')"> -->
        <div class="main" v-html="artical.content">
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            artical:''
        }
    },
    created(){
     this.loadData(this.$route.query.id)
    },
    methods:{
        loadData(id){
                        this.$api.post('articles.getArticleDetail',{article_id:id},(res)=>{
                            if(res.data.status){
                                this.artical=res.data.data
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        })
        }
    }
}
</script>
<style lang="scss" scoped>
 .main{
        text-align: left;
    }
</style>